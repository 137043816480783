import { useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import { RxHamburgerMenu } from "react-icons/rx";
// import logo from "../../assets/contracttokeys.png"
import logo from "../../assets/C2K.png"

import { logoutUser } from "../../utils/CognitoAuth";
import { logout } from "../../features/authSlice";

const Navbar = () => {
    const { email, role, agentEmail } = useSelector((state) => state.auth);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    let pathname = location.pathname

    let loggedIn = false
    email ? loggedIn = true : loggedIn = false

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleLogout = () => {
        // signOut()
        logoutUser()
        dispatch(logout())
        localStorage.clear()
        navigate("/login")
    }

    return (
        <nav className='bg-blue-700 border-b border-blue-500'>
            <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
                <div className='relative flex h-20 items-center justify-evenly'>
                    <div className='absolute inset-y-0 left-0 flex items-center md:hidden'>
                        {/* Mobile Menu button */}
                        <button
                            className='relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                            onClick={() => setIsMobileMenuOpen((prev) => !prev)}
                        >
                            <RxHamburgerMenu size={40} />

                        </button>
                    </div>

                    <div className='flex flex-1 items-center justify-center md:items-stretch md:justify-start'>
                        <div>
                            <Link to="/" className='flex flex-shrink-0 items-center'>
                                {/* <FaHouseUser className="text-white" size={40} /> */}
                                <img className="object-fit h-20 w-20" src={logo} alt="logo" />
                                <div className='ml-2 text-white'>
                                    <p className='hidden md:block text-2xl font-bold'> Contract To Keys</p>
                                    <p className='hidden md:block text-sm font-semibold italic'>Your Closing Countdown
                                    </p>
                                </div>
                            </Link>
                        </div>

                        {/* Desktop meny; Hidden on below md screens */}
                        <div className='hidden md:ml-6 md:block'>
                            <div className='flex space-x-2 mt-5'>
                                {loggedIn && role === "Agent" && (
                                    <div className='mt-2'>
                                        <Link
                                            to="/add-contract"
                                            className={`${pathname === "/add-contract" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}
                                        >
                                            Add Contract
                                        </Link>
                                        <Link
                                            to="/"
                                            className={`${pathname === "/" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}
                                        >
                                            Open
                                        </Link>

                                        <Link
                                            to="/closed-contracts"
                                            className={`${pathname === "/closed-contracts" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}
                                        >
                                            Closed
                                        </Link>
                                        <Link to="/sub"
                                            className={`${pathname === "/sub" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}>
                                            Subscriptions
                                        </Link>
                                        <Link to="/sub/add-users"
                                            className={`${pathname === "/sub/add-users" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}>
                                            Manage Users
                                        </Link>
                                    </div>
                                )}


                                {loggedIn && (role === "Transaction Coordinator" && !agentEmail) && (
                                    <div className='mt-2'>
                                        <Link to="/select-agent"
                                            className={`${pathname === "/select-agent" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}>
                                            Select Agent
                                        </Link>
                                    </div>
                                )}

                                {loggedIn && role === "Transaction Coordinator" && agentEmail && (
                                    <div className='mt-2'>
                                        <Link
                                            to="/add-contract"
                                            className={`${pathname === "/add-contract" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}
                                        >
                                            Add Contract
                                        </Link>
                                        <Link
                                            to="/"
                                            className={`${pathname === "/" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}
                                        >
                                            Open
                                        </Link>

                                        <Link
                                            to="/closed-contracts"
                                            className={`${pathname === "/closed-contracts" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}
                                        >
                                            Closed
                                        </Link>
                                        <Link to="/select-agent"
                                            className={`${pathname === "/select-agent" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}>
                                            Select Agent
                                        </Link>
                                    </div>
                                )}

                                {loggedIn && (role === "Buyer" || role === "Seller") && (
                                    <div className='mt-2'>

                                        <Link
                                            to="/"
                                            className={`${pathname === "/" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}
                                        >
                                            Open
                                        </Link>

                                        <Link
                                            to="/closed-contracts"
                                            className={`${pathname === "/closed-contracts" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}
                                        >
                                            Closed
                                        </Link>

                                    </div>
                                )}

                                <Link
                                    to="/about"
                                    className={`${pathname === "/about" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}
                                >
                                    About
                                </Link>

                                <Link
                                    to="/invitation"
                                    className={`${pathname === "/invitation" ? 'bg-black' : ''} text-white hover:bg-gray-900 hover:text-white rounded-md px-3 py-2`}
                                >
                                    Share
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Right side menu */}
                    <div className='absolute right-0 text-right'>
                        {
                            loggedIn ? (
                                <div className='text-white'>
                                    <div><button
                                        className='font-bold uppercase'
                                        onClick={handleLogout}>
                                        Logout
                                    </button></div>

                                </div>
                            ) : (
                                <div className='flex justify-around'>
                                    <div className='font-bold text-white'>
                                        <Link to="/login">Login</Link>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className='text-[12px] text-white text-right mb-2'>
                    {email && (<div className='flex justify-around'>
                        <div className='italic'>
                            Logged in as: {email} ({role})
                        </div>

                    </div>)}
                    {role === "Transaction Coordinator" && agentEmail && (
                        <div className='italic'>
                            Agent: {agentEmail}
                        </div>
                    )}
                </div>



            </div>

            {/* Mobile Menu */}
            {
                isMobileMenuOpen && (
                    <div>
                        <div className='space-y-1 px-2 pb-3 pt-2'>
                            {loggedIn && role === "Agent" && (
                                <div className='mt-2'>
                                    <Link
                                        to="/add-contract"
                                        className={`${pathname === "/add-contract" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                        onClick={() => setIsMobileMenuOpen(false)}
                                    >
                                        Add Contract
                                    </Link>
                                    <Link
                                        to="/"
                                        className={`${pathname === "/" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                        onClick={() => setIsMobileMenuOpen(false)}
                                    >
                                        Open
                                    </Link>

                                    <Link
                                        to="/closed-contracts"
                                        className={`${pathname === "/closed-contracts" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                        onClick={() => setIsMobileMenuOpen(false)}
                                    >
                                        Closed
                                    </Link>
                                    <Link to="/sub"
                                        className={`${pathname === "/sub" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                        onClick={() => setIsMobileMenuOpen(false)}
                                    >
                                        Subscriptions
                                    </Link>
                                    <Link to="/sub/add-users"
                                        className={`${pathname === "/sub/add-users" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                        onClick={() => setIsMobileMenuOpen(false)}
                                    >
                                        Manage Users
                                    </Link>
                                </div>
                            )}


                            {loggedIn && (role === "Transaction Coordinator" && !agentEmail) && (
                                <div className='mt-2'>
                                    <Link to="/select-agent"
                                        className={`${pathname === "/select-agent" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                        onClick={() => setIsMobileMenuOpen(false)}>
                                        Select Agent
                                    </Link>
                                </div>
                            )}

                            {loggedIn && role === "Transaction Coordinator" && agentEmail && (
                                <div className='mt-2'>
                                    <Link
                                        to="/add-contract"
                                        className={`${pathname === "/add-contract" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                        onClick={() => setIsMobileMenuOpen(false)}
                                    >
                                        Add Contract
                                    </Link>
                                    <Link
                                        to="/"
                                        className={`${pathname === "/" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                        onClick={() => setIsMobileMenuOpen(false)}
                                    >
                                        Open
                                    </Link>

                                    <Link
                                        to="/closed-contracts"
                                        className={`${pathname === "/closed-contracts" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                        onClick={() => setIsMobileMenuOpen(false)}
                                    >
                                        Closed
                                    </Link>
                                    <Link to="/select-agent"
                                        className={`${pathname === "/select-agent" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                        onClick={() => setIsMobileMenuOpen(false)}>
                                        Select Agent
                                    </Link>
                                </div>
                            )}

                            <Link
                                to="/about"
                                className={`${pathname === "/about" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                onClick={() => setIsMobileMenuOpen(false)}
                            >
                                About
                            </Link>

                            <Link
                                to="/invitation"
                                className={`${pathname === "/invitation" ? 'bg-black' : ''} text-white block rounded-md px-3 py-2 text-base font-medium`}
                                onClick={() => setIsMobileMenuOpen(false)}
                            >
                                Share
                            </Link>
                        </div>
                    </div>
                )
            }

        </nav>
    )
}

export default Navbar