import { useSelector } from 'react-redux';

import Dashboard from "./Dashboard"
import SelectAgent from "./SelectAgent"

const ClosedContracts = () => {

    const { role, agentEmail } = useSelector((state) => state.auth);

    if (role === "Transaction Coordinator" && !agentEmail) {
        return <SelectAgent />
    } else {
        return <Dashboard status="Closed" />
    }

}

export default ClosedContracts